.error-panel {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
}

h1,
h2,
p {
  margin: 0;
  padding: 0;

  &:first-letter {
    text-transform: capitalize;
  }
}

.error-container {
  position: absolute;
  z-index: 99;
  left: 0;
  top: 0;
  overflow: hidden;

  background-color: #f2f2f2;
  display: flex;

  width: 100%;
  height: 100%;

  .left-panel {
    background-color: #f7b500;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .right-panel {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &-tickertape {
    .left-panel {
      width: 110vh;

      img {
        height: 60vh;
      }
    }

    .right-panel {
      margin-left: 30vh;

      h1 {
        font-size: 40vh;
        font-weight: normal;
      }
    }
  }

  &-zones {
    .left-panel {
      width: 37vw;
      margin-right: 6vw;

      img {
        height: 17vh;
      }
    }

    h1 {
      color: #f7b500;
      font-size: calc(2vh + 2vw);
      margin-bottom: 1vh;
    }

    h2 {
      font-size: calc(1.5vh + 1.5vw);
      margin-bottom: 2vh;
    }

    p {
      font-size: calc(1.25vh + 1.25vw);
      margin-top: 1vh;
      font-weight: light;
    }
  }
}

@media screen and (orientation: portrait) {
  .error-container {
    flex-direction: column;

    .left-panel {
      height: 40vh;
      width: 100vw;

      img {
        width: 20vw;
      }
    }

    .right-panel {
      height: 60vh;
      align-items: center;
      justify-content: flex-start;
      padding-top: 8vh;
    }
  }
}
