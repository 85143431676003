.loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: black;
}

.error {
  width: auto;
  height: 120px;
  position: fixed;
  z-index: 999;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
}

.slide {
  background-color: black;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: absolute;
  background-position: center;
}

.error-panel {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black; }

h1,
h2,
p {
  margin: 0;
  padding: 0; }
  h1:first-letter,
  h2:first-letter,
  p:first-letter {
    text-transform: capitalize; }

.error-container {
  position: absolute;
  z-index: 99;
  left: 0;
  top: 0;
  overflow: hidden;
  background-color: #f2f2f2;
  display: flex;
  width: 100%;
  height: 100%; }
  .error-container .left-panel {
    background-color: #f7b500;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; }
  .error-container .right-panel {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; }
  .error-container-tickertape .left-panel {
    width: 110vh; }
    .error-container-tickertape .left-panel img {
      height: 60vh; }
  .error-container-tickertape .right-panel {
    margin-left: 30vh; }
    .error-container-tickertape .right-panel h1 {
      font-size: 40vh;
      font-weight: normal; }
  .error-container-zones .left-panel {
    width: 37vw;
    margin-right: 6vw; }
    .error-container-zones .left-panel img {
      height: 17vh; }
  .error-container-zones h1 {
    color: #f7b500;
    font-size: calc(2vh + 2vw);
    margin-bottom: 1vh; }
  .error-container-zones h2 {
    font-size: calc(1.5vh + 1.5vw);
    margin-bottom: 2vh; }
  .error-container-zones p {
    font-size: calc(1.25vh + 1.25vw);
    margin-top: 1vh;
    font-weight: light; }

@media screen and (orientation: portrait) {
  .error-container {
    flex-direction: column; }
    .error-container .left-panel {
      height: 40vh;
      width: 100vw; }
      .error-container .left-panel img {
        width: 20vw; }
    .error-container .right-panel {
      height: 60vh;
      align-items: center;
      justify-content: flex-start;
      padding-top: 8vh; } }

/**
  * NB
  * - Only place global styles here. Styles for individual components should live in each component's folder.
  * - normalize.css is first imported via NPM in index.ts
  */

* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px; /* i.e. 1rem */
}

body {
  font-family: Lato, "sans-serif", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

